import React from 'react'
import { Container } from 'react-bootstrap'

import HostingOverviewSection from './HostingOverviewSection'
import HostingGetStartedSection from './HostingGetStartedSection'
import HostingPricingSection from './HostingPricingSection'

const HostingPage = () => {
  return (
    <Container>
      <HostingOverviewSection />
      <HostingGetStartedSection />
      <HostingPricingSection />
    </Container>
  )
}

export default HostingPage
