import React from 'react'
import { Button, Container, Card, Modal, Row, Stack } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { acceptCookies } from '../../store/slices/appSlice'

import './CookieNotification.css'

const CookieCard =  ({ readingPrivacyPolicy }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const handleAccept = (e) => {
    e.preventDefault()
    dispatch(acceptCookies())
    if (readingPrivacyPolicy) {
      history.push("/")
    }
  }
  return (
    <Card className="w-100">
      <Card.Header>
        {t("cookie-notification-header")}
      </Card.Header>
      <Card.Body>
        <Stack gap={2}>
          <Container>
            <p>{t("cookie-notification-brief")}</p>
          </Container>
          <Row>
            <Button onClick={handleAccept}>{t("cookie-notification-accept")}</Button>
          </Row>
          {!readingPrivacyPolicy && (
            <Row className="text-center">
              <Link to="/privacy">{t("cookie-notification-read-privacy-policy")}</Link>
            </Row>
          )}
        </Stack>
      </Card.Body>
    </Card>
  )
}

const CookieNotification = () => {
  const { cookieConsent, readingPrivacyPolicy, scrolled } = useSelector(state => state.appControl)
  if (!scrolled || cookieConsent) {
    return null
  } else if (readingPrivacyPolicy) {
    return (
      <Container className="cookie-notification w-100 m-md-2">
        <CookieCard readingPrivacyPolicy={readingPrivacyPolicy} />
      </Container>
    )
  }

  return (
    <Modal centered show={!cookieConsent}>
      <CookieCard readingPrivacyPolicy={readingPrivacyPolicy} />
    </Modal>
  )
}

export default CookieNotification
