import React from 'react'
import { Button, Container, Modal, Row, Stack } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next"

import { deleteWebsite } from '../../store/slices/websitesSlice'

const ConfirmWebsiteDeleteModal = ({ id, show, onHide }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const handleDelete = async (e) => {
    e.preventDefault()
    const { meta } = await dispatch(deleteWebsite({ id }))
    if (meta.requestStatus === "fulfilled") {
      history.push(`/dashboard/websites`)
    }
  }

  return (
    <Modal centered show={show} onHide={onHide}>
      <Modal.Header closeButton>{t("confirm-website-delete-header")}</Modal.Header>
      <Modal.Body>
        <Container>
          <Stack gap={3}>
            <Container>{t("confirm-website-delete-confirmation")}</Container>
            <Container>{t("confirm-website-delete-disclaimer")}</Container>
            <Row>
              <Button variant="danger" onClick={handleDelete}>{t("website-information-management-delete-text")}</Button>
            </Row>
          </Stack>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

export default ConfirmWebsiteDeleteModal
