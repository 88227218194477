import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, InputGroup, Stack } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next"

import { createWebsite } from '../../store/slices/websitesSlice'

const WebsiteForm = ({ website }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const alias = `${e.target.domainPrefix.value}.anteronweb.com`
    const { meta, payload } = await dispatch(createWebsite({ alias }))
    const { requestStatus } = meta
    if (requestStatus === "fulfilled") {
      history.push(`/dashboard/websites/${payload.id}`)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={5}>
        <h1 className="fw-light text-center">{t("website-form-information-header")}</h1>
        <Form.Group className="mb-3">
          <Form.Label>{t("website-form-information-domain-label")}</Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              name="domainPrefix"
              placeholder={t("website-form-information-domain-placeholder")}
            />
            <InputGroup.Text>
              .anteronweb.com
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Button variant="primary" type="submit">{t("website-form-create-submit-text")}</Button>
      </Stack>
    </Form>
  )
}

export default WebsiteForm
