import _ from 'lodash'
import { useTranslation } from "react-i18next";
import { Formik, useFormikContext } from 'formik'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Accordion, Button, Col, Form, Row, Stack } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useHistory } from 'react-router-dom'

import "react-datepicker/dist/react-datepicker.css";
import "./AssignmentForm.css"

import OrganizationModal from '../organization/OrganizationModal'
import { deleteAssignment } from '../../store/slices/assignmentsSlice'

const DateField = ({ name, value, errors, touched, onChange }) => {
  const { setFieldValue } = useFormikContext()

  return (
    <Form.Control
      as={DatePicker}
      type="text"
      name={name}
      selected={value}
      dateFormat="yyyy-MM-dd"
      onChange={v => {onChange(); setFieldValue(name, v)}}
      isInvalid={touched && !!errors}
      isValid={touched && !errors}
    />
  )
}

const preprocessDate = (date) => {
  if (!date) {
    return null
  } else if (typeof(date) === "object") {
    const isoString = date.toISOString()

    return isoString.substring(0, isoString.indexOf('T'))
  }
  return date
}

const AssignmentForm = ({ initialValues, submit, submitText }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const [ changed, setChanged ] = useState(false)
  const { organizations } = useSelector(state => state.organizations)

  const handleOnSubmit = (assignment) => {
    if (!submit) {
      return
    }
    const { id, clientId, createdBy, updatedAt, ...assignmentData } = assignment
    assignmentData.startDate = preprocessDate(assignmentData.startDate)
    assignmentData.endDate = preprocessDate(assignmentData.endDate)
    submit({ id, clientId, assignmentData })
  }

  const validateForm = (values) => {
    const errors = {}

    if (!values.title) {
      errors.title = t("assignment-form-error-title-required")
    }

    if (!values.clientId || values.clientId === "1" || values.clientId === "2") {
      errors.clientId = t("assignment-form-error-client-required")
    }

    if (values.startDate && values.endDate) {
      const startDate = preprocessDate(values.startDate)
      const endDate = preprocessDate(values.endDate)
      if (endDate < startDate) {
        errors.endDate = t("assignment-form-error-end-date-before-start")
      }
    }

    return errors
  }

  const handleClientSelectChange = (handleChange) => (e) => {
    if (e.target.value === "1") {
      return
    } else if (e.target.value === "2") {
      history.push("/dashboard/organizations/create")
    }
    setChanged(true)
    handleChange(e)
  }

  return (
    <Formik
      initialValues={initialValues || {}}
      onSubmit={handleOnSubmit}
      validate={validateForm}
    >
      {({
        values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <OrganizationModal />
          <Accordion>
            <Stack gap={5}>
              <Form.Group>
                <Stack gap={3}>
                  <Form.Control
                    className="page-header"
                    type="text"
                    name="title"
                    onChange={e => {setChanged(true) ; handleChange(e)}}
                    isInvalid={!!touched.title && !!errors.title}
                    isValid={touched.title && !errors.title}
                    value={values.title || ""}
                    placeholder={t("assignment-form-title-placeholder")}
                  />
                  {errors.title && touched.title && <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>}
                </Stack>
              </Form.Group>
              <Form.Group>
                <Stack gap={3}>
                  <Form.Group>
                    <Form.Label>{t("assignment-form-client-label")}</Form.Label>
                    <Form.Control
                      as={Form.Select}
                      type="text"
                      name="clientId"
                      value={values.clientId || "1"}
                      onChange={handleClientSelectChange(handleChange)}
                      isInvalid={changed && !!errors.clientId}
                      isValid={changed && !errors.clientId}
                    >
                      <option value="1">{t("assignment-form-client-select-index")}</option>
                      {organizations.length > 0 ? (
                        <>
                          {organizations.map(organization => <option key={organization.id} value={organization.id}>{organization.name}</option>)}
                        </>
                      ) : (
                        <option value="2">{t("assignment-form-client-select-register")}</option>
                      )}
                    </Form.Control>
                    {errors.clientId && <Form.Control.Feedback type="invalid">{errors.clientId}</Form.Control.Feedback>}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t("assignment-form-description-label")}</Form.Label>
                    <Form.Control
                      style={{ "minHeight": "12em" }}
                      as="textarea"
                      type="text"
                      name="description"
                      value={values.description || ""}
                      onChange={e => {setChanged(true) ; handleChange(e)}}
                      isInvalid={touched.description && !!errors.description}
                      isValid={touched.description && !errors.description}
                    />
                    {errors.description && <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>}
                  </Form.Group>
                </Stack>
              </Form.Group>
              <Form.Group>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>{t("assignment-form-schedule-header")}</Accordion.Header>
                  <Accordion.Body>
                    <Stack gap={3}>
                      <Form.Group>
                        <Row>
                          <Col>
                            <Form.Label>{t("assignment-form-start-date-label")}</Form.Label>
                            <DateField
                              name="startDate"
                              value={values.startDate ? new Date(values.startDate) : null}
                              errors={errors.startDate}
                              touched={touched.startDate}
                              onChange={() => setChanged(true)}
                            />
                          </Col>
                          <Col>
                            <Form.Label>{t("assignment-form-end-date-label")}</Form.Label>
                            <DateField
                              name="endDate"
                              value={values.endDate ? new Date(values.endDate) : null}
                              errors={errors.endDate}
                              touched={touched.endDate}
                              onChange={() => setChanged(true)}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Stack>
                  </Accordion.Body>
                </Accordion.Item>
              </Form.Group>
              <Form.Group>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>{t("assignment-form-additional-data-header")}</Accordion.Header>
                  <Accordion.Body>
                    <Stack gap={3}>
                      <Form.Group>
                        <Form.Label>{t("assignment-form-key-technologies-label")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="technologies"
                          value={values.technologies || ""}
                          onChange={e => {setChanged(true) ; handleChange(e)}}
                          isInvalid={touched.technologies && !!errors.technologies}
                          isValid={touched.technologies && !errors.technologies}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t("assignment-form-business-domain-label")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="domain"
                          value={values.domain || ""}
                          onChange={e => {setChanged(true) ; handleChange(e)}}
                          isInvalid={touched.domain && !!errors.domain}
                          isValid={touched.domain && !errors.domain}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>{t("assignment-form-external-links-label")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="links"
                          value={values.links || ""}
                          onChange={e => {setChanged(true) ; handleChange(e)}}
                          isInvalid={touched.links && !!errors.links}
                          isValid={touched.links && !errors.links}
                        />
                      </Form.Group>
                    </Stack>
                  </Accordion.Body>
                </Accordion.Item>
              </Form.Group>
              {values.id && (
                <Form.Group>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>{t("assignment-form-manage-label")}</Accordion.Header>
                    <Accordion.Body>
                      <Stack gap={3}>
                        <Form.Group>
                          <Button variant="danger" onClick={e => dispatch(deleteAssignment(values.id))}>
                            {t("assignment-form-manage-delete-label")}
                          </Button>
                        </Form.Group>
                      </Stack>
                    </Accordion.Body>
                  </Accordion.Item>
                </Form.Group>
              )}
              {changed && <Button disabled={!_.isEmpty(errors)} variant="primary" type="submit">{submitText}</Button>}
            </Stack>
          </Accordion>
        </Form>
      )}
    </Formik>
  )
}

export default AssignmentForm
