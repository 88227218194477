import React from 'react'
import { Col } from 'react-bootstrap'

import FrontCarousel from './FrontCarousel'
import ServiceCards from './ServiceCards'
import DevelopAgileSection from './DevelopAgileSection'
import PayAsYouGoSection from './PayAsYouGoSection'
import OverviewSection from './OverviewSection'

import AboutSection from './AboutSection'

const FrontPage = () => {
  return (
    <Col className="h-100" style={{ "background": "white" }}>
      <FrontCarousel />
      <main className="py-5">
        <OverviewSection />
        <ServiceCards />
        <PayAsYouGoSection />
        <DevelopAgileSection />
        <AboutSection />
      </main>
    </Col>
  )
}

export default FrontPage
