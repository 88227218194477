import React from 'react'
import { useTranslation } from "react-i18next";
import { Nav, NavDropdown } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setShowLoginModal } from '../../store/slices/appSlice'
import { logout } from '../../store/slices/userSlice'

import './UserDropdown.css'

const UserDropdown = () => {
  const { authToken, email, userData } = useSelector(state => state.user)
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const handleLogout = async (e) => {
    await dispatch(logout())
    history.push("/")
  }

  if (!authToken) {
    return (
      <Nav.Link eventKey="3" onClick={e => dispatch(setShowLoginModal(true))} >{t("user-dropdown-login-register")}</Nav.Link>
    )
  }

  return (
    <NavDropdown align="end" title={userData.firstName || email}>
      <NavDropdown.Item className="text-end pe-4" eventKey="4" onClick={handleLogout}>{t("user-dropdown-logout")}</NavDropdown.Item>
    </NavDropdown>
  )
}

export default UserDropdown
