import React from 'react'
import { useTranslation } from "react-i18next";
import { Container, Col, Row } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fa1, fa2 } from '@fortawesome/free-solid-svg-icons'

import './HostingGetStartedSection.css'

const HostingGetStartedSection = () => {
  const { t } = useTranslation()
  return (
    <Container className="mt-5">
      <h2 className="fw-light text-center">{t("hosting-page-getting-started-header")}</h2>
      <Container className="text-center">{t("hosting-page-getting-started-intro")}</Container>
      <Row className="mt-5">
        <Col className="m-3 row-content">
          <h4 className="fw-light">
            <FontAwesomeIcon icon={fa1} />{' '}
            {t("hosting-page-step1-header")}
          </h4>
          <Container className="p-2">
            {t("hosting-page-step1-pt-1")}{' '}
            <a href="https://quarkly.io/" rel="noreferrer" target="_blank">Quarkly</a>
            {t("hosting-page-step1-pt-2")}
          </Container>
        </Col>
        <Col className="row-filler" />
      </Row>
      <Row className="mt-5">
        <Col className="row-filler" />
        <Col className="m-3 row-content">
          <h4 className="fw-light">
            <FontAwesomeIcon icon={fa2} />{' '}
            {t("hosting-page-step2-header")}
          </h4>
          <Container className="p-2">
            {t("hosting-page-step2-pt-1")}
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default HostingGetStartedSection
