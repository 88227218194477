import React from 'react'
import { useTranslation } from "react-i18next";
import { Container, Stack, Table } from 'react-bootstrap'

const ConsultingPricingTable = () => {
  const { t } = useTranslation()
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>{t("hosting-pricing-table-cost-header")}</th>
          <th>{t("hosting-pricing-table-price-header")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("consulting-pricing-table-consulting-label")}</td>
          <td>100 €/{t("unit-hour")}</td>
        </tr>
      </tbody>
    </Table>
  )
}

const ConsultingPage = () => {
  const { t } = useTranslation()
  return (
    <Container className="text-center p-5">
      <h1 className="fw-light">{t("consulting-page-title")}</h1>
      <Container className="p-3">
        <Stack gap={3}>
          <h2 className="fw-light">{t("hosting-page-pricing-header")}</h2>
          <ConsultingPricingTable />
        </Stack>
      </Container>
    </Container>
  )
}

export default ConsultingPage
