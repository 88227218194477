import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button, Container, Row, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'

import { fetchWebsiteBuilds, startWebsiteBuild } from '../../store/slices/websitesSlice'

const WebsiteBuildTable = ({ websiteId, websiteBuilds }) => {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Started</th>
            <th>Finished</th>
            <th>Status</th>
            <th>Build time</th>
          </tr>
        </thead>
        <tbody>
          {websiteBuilds.sort((a, b) => new Date(b.startTime) - new Date(a.startTime)).map(b => (
            <tr key={b.buildId}>
              <td>{new Date(b.startTime).toLocaleString()}</td>
              <td>{b.endTime && new Date(b.endTime).toLocaleString()}</td>
              <td>{b.buildStatus}</td>
              <td>{b.buildTimeSeconds} s</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

const WebsiteStatusSection = ({ website }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { loading, builds } = useSelector(state => state.websites)
  const { id } = website

  const websiteBuilds = builds.filter(b => b.websiteId === id)

  useEffect(() => {
    dispatch(fetchWebsiteBuilds(id))
  }, [dispatch, id])

  const handleRefresh = async (event) => {
    if (!loading) {
      const { payload } = await dispatch(fetchWebsiteBuilds(id))
      const polledBuilds = payload.filter(b => b.websiteId === id).sort((a, b) => new Date(b.startTime) - new Date(a.startTime))
      if (polledBuilds.length > 0) {
        const lastBuild = polledBuilds[0]
        if (lastBuild.buildStatus === "SUCCEEDED") {
          const pathVars = url.split("/")
          let targetUrl = ""
          for (let i = 0; i < pathVars.length - 1; i++) {
            targetUrl += pathVars[i] + "/"
          }

          history.push(`${targetUrl}publish`)
        }
      }
    }
  }

  const handleStartBuild = (event) => {
    dispatch(startWebsiteBuild({ id }))
  }

  return (
    <Container className="p-3">
      <h3 className="fw-light mb-5">
        Builds{' '}
        <Button variant="link" disabled={loading} onClick={handleRefresh} style={{outline: "none", boxShadow: "none"}}>
          <FontAwesomeIcon className={`${loading ? "fa-spin" : ""} m-auto`} icon={faRefresh} />{' '}
        </Button>
      </h3>
      <Row className="justify-content-between">
        <WebsiteBuildTable websiteId={website.id} websiteBuilds={websiteBuilds} />
        <Button onClick={handleStartBuild}>Start a new Build</Button>
      </Row>
    </Container>
  )
}

export default WebsiteStatusSection
