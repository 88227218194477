import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import { Container, Spinner, Stack } from 'react-bootstrap'

import { fetchOrganizationsForAuthenticatedUser } from '../../store/slices/organizationsSlice'

import OrganizationsList from './OrganizationsList'

const OrganizationsPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { loading: userLoading } = useSelector(state => state.user)

  useEffect(() => {
    dispatch(fetchOrganizationsForAuthenticatedUser())
  }, [dispatch])

  if (userLoading) {
    return (
      <Container>
        <Spinner animation="border" size="sm" /> {t("organizations-page-fetching")}
      </Container>
    )
  }

  return (
    <Container className="p-5">
      <Stack gap={5}>
        <h1 className="text-center">{t("organizations")}</h1>
        <OrganizationsList />
      </Stack>
    </Container>
  )
}

export default OrganizationsPage
