import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { Col, Container, ListGroup, Stack } from 'react-bootstrap'

import { readingPrivacyPage } from '../../store/slices/appSlice'

import './page.css'

const PrivacyPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(readingPrivacyPage(true))
    return () => {
      dispatch(readingPrivacyPage(false))
    }
  }, [dispatch])

  return (
    <Col className="page-container h-100 py-5">
      <Stack gap={5}>
        <h1 className="text-center fw-light">{t("privacy-policy-header")}</h1>
        <Container className="lead">
          <p>{t("privacy-policy-intro")}</p>
        </Container>
        <h2 className="text-center fw-light">{t("privacy-policy-personal-info-header")}</h2>
        <Container>
          <p className="lead mb-4">{t("privacy-policy-personal-info-body")}</p>
          <ListGroup as="ol" numbered>
            <ListGroup.Item as="li">{t("e-mail-address")}</ListGroup.Item>
            <ListGroup.Item as="li">{t("first-and-or-last-name")}</ListGroup.Item>
            <ListGroup.Item as="li">{t("phone-number")}</ListGroup.Item>
          </ListGroup>
        </Container>
        <h2 className="text-center fw-light">{t("third-party-analytics-header")}</h2>
        <Container className="lead">
          <p>{t("third-party-analytics-intro")}</p>
        </Container>
        <h3 className="text-center fw-light">{t("third-party-analytics-google-header")}</h3>
        <Container>
          <p className="lead mb-4">{t("third-party-analytics-google-body")}</p>
          <ListGroup as="ol" numbered>
            <ListGroup.Item as="li">{t("number-of-website-visitors")}</ListGroup.Item>
            <ListGroup.Item as="li">{t("visitors-country-of-origin")}</ListGroup.Item>
          </ListGroup>
        </Container>
        <h2 className="text-center fw-light">{t("users-rights-header")}</h2>
        <Container className="lead">
          <p>{t("users-rights-body")}</p>
        </Container>
        <h3 className="text-center fw-light">{t("users-rights-delete-pii-header")}</h3>
        <Container className="lead">
          <p>{t("users-rights-delete-pii-body")}</p>
        </Container>
      </Stack>
    </Col>
  )
}

export default PrivacyPage
