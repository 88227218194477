import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

const UserPasswordFields = ({ values, handleChange, touched, errors }) => {
  const { t } = useTranslation()
  return (
    <>
      <Form.Label>{t("user-password-fields-password")}</Form.Label>
      <Form.Control
        type="password"
        name="password"
        onChange={handleChange}
        isInvalid={!!touched.password && !!errors.password}
        isValid={touched.password && !errors.password}
        value={values.password}
      />
      {errors.password && touched.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
      <Form.Label>{t("user-password-fields-confirm-password")}</Form.Label>
      <Form.Control
        type="password"
        name="confirmPassword"
        onChange={handleChange}
        isInvalid={!!touched.confirmPassword && !!errors.confirmPassword}
        isValid={touched.confirmPassword && !errors.confirmPassword}
        value={values.confirmPassword}
      />
      {errors.confirmPassword && touched.confirmPassword && <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>}
    </>
  )
}

export default UserPasswordFields
