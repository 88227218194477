import React from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import { register } from '../../store/slices/organizationsSlice.js'

import OrganizationForm from './OrganizationForm'

const CreateOrganization = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const handleCreateOrganization = async (newOrganization) => {
    const result = await dispatch(register(newOrganization))
    const { requestStatus } = result.meta
    if (requestStatus === "fulfilled") {
      history.push("/dashboard/organizations")
    }
  }

  return (
    <Container className="p-5">
      <OrganizationForm
        organization={null}
        onSubmit={handleCreateOrganization}
        submitText={t("create-organization-submit-text")}
      />
    </Container>
  )
}

export default CreateOrganization
