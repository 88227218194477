import React from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { setShowLoginModal } from '../../../store/slices/appSlice'

const FrontCreateAssignmentLink = () => {
  const { t } = useTranslation()
  return (
    <>
      <Link to="/dashboard/assignments/create">{t("front-welcome-create-assignment")}</Link> {t("front-welcome-create-text")}
    </>
  )
}

const FrontRegisterLink = ({ handleOnClick }) => {
  const { t } = useTranslation()
  return (
    <>
      <Link to="" onClick={handleOnClick}>{t("front-welcome-login-register")}</Link> {t("front-welcome-login-register-text")}
    </>
  )
}

const AboutSection = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { authToken } = useSelector(state => state.user)

  const handleRegisterWithEmail = (e) => {
    e.preventDefault()
    dispatch(setShowLoginModal(true))
  }
  return (
    <Container className="p-3 p-lg-5">
      <h2 className="fw-light text-center">{t("front-header")}</h2>
      <Col className="m-3 mx-auto text-center lead">
        {t("front-welcome-2")}
      </Col>
      <Col className="m-3 mx-auto text-center lead">
        {authToken ? <FrontCreateAssignmentLink /> : <FrontRegisterLink handleOnClick={handleRegisterWithEmail} />}
      </Col>
    </Container>
  )
}

export default AboutSection
