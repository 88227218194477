import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Card, Col, Container, Row, Stack } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

import { setShowLoginModal } from '../../../store/slices/appSlice'

const ServiceCards = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { authToken } = useSelector(state => state.user)

  const handleClickHosting = (event) => {
    if (authToken) {
      history.push("/dashboard/websites/create")
    } else {
      dispatch(setShowLoginModal(true))
    }
  }

  const handleClickConsulting = (event) => {
    if (authToken) {
      history.push("/dashboard/assignments/create")
    } else {
      dispatch(setShowLoginModal(true))
    }
  }

  return (
    <Container className="p-3 bg-dark bg-gradient" fluid>
      <Row className="mx-auto p-3 justify-content-between" style={{ maxWidth: "72em" }}>
        <Col className="m-2" style={{ maxWidth: "32em" }}>
          <Card>
            <Card.Header><h4 className="fw-light text-center">{t("service-cards-hosting-header")}</h4></Card.Header>
            <Card.Body>
              <Container>
                <Stack gap={4}>
                  <Card.Title className="text-center">
                    <Col>
                      0.01 €<small className="text-muted">/ {t("service-cards-hosting-gb")}</small>{' '}
                    </Col>
                  </Card.Title>
                  <ul className="list-unstyled">
                    <li className="py-1">{t("service-cards-hosting-li-1")}</li>
                    <li className="py-1">{t("service-cards-hosting-li-2")}</li>
                    <li className="py-1">{t("service-cards-hosting-li-3")}</li>
                  </ul>
                </Stack>
              </Container>
              <Button className="rounded-pill" onClick={handleClickHosting}>
                {t(authToken ? "website-form-create-submit-text" : "front-welcome-login-register")}
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col className="m-2" style={{ maxWidth: "32em" }}>
          <Card>
            <Card.Header className="text-center"><h4 className="fw-light">{t("service-cards-consulting-header")}</h4></Card.Header>
            <Card.Body>
              <Container>
                <Stack gap={4}>
                  <Card.Title className="text-center">
                    100 €<small className="text-muted">/ {t("service-cards-consulting-hour")}</small>
                  </Card.Title>
                  <ul className="list-unstyled">
                    <li className="py-1">{t("service-cards-consulting-li-1")}</li>
                    <li className="py-1">{t("service-cards-consulting-li-2")}</li>
                    <li className="py-1">{t("service-cards-consulting-li-3")}</li>
                  </ul>
                </Stack>
              </Container>
              <Button className="rounded-pill" onClick={handleClickConsulting}>
                {t(authToken ? "service-cards-consulting-create" : "front-welcome-login-register")}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default ServiceCards
