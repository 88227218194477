import React from 'react'
import { useTranslation } from "react-i18next";
import { Col, Container } from 'react-bootstrap'

const DevelopAgileSection = () => {
  const { t } = useTranslation()
  return (
    <Container className="p-3 p-lg-5">
      <h2 className="fw-light text-center">{t("developagile-header")}</h2>
      <Col className="m-3 mx-auto text-center lead">
        {t("developagile-body")}
      </Col>
    </Container>
  )
}

export default DevelopAgileSection
