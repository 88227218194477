import React from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import AssignmentForm from './AssignmentForm'

import { create } from '../../store/slices/assignmentsSlice'

const CreateAssignment = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const submit = (newAssignment) => {
    dispatch(create(newAssignment))
    history.push("/dashboard/assignments")
  }

  return (
    <Container className="p-5">
      <AssignmentForm
        assignment={{}}
        submit={submit}
        submitText={t("create-assignment-submit-text")}
      />
    </Container>
  )
}

export default CreateAssignment
