import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

import { postLogin } from '../../api/authAPI'
import { getUser, postUser, postVerifyUser, postResetPassword, postUserPassword, putUser } from '../../api/userAPI'

import { acceptCookies, showMessage } from './appSlice'
import { clearOrganizations } from './organizationsSlice'
import { clearAssignments } from './assignmentsSlice'

const LS_AUTH_TOKEN = 'authToken'

export const showErrorMessage = createAsyncThunk(
  'user/showErrorMessage',
  async (message, { dispatch, rejectWithValue }) => {
    dispatch(addErrorMessage(message))
    setTimeout(() => {
      dispatch(removeErrorMessage(message))
    }, 5000)
  }
)

/**
 * Login to API with provided credentials.
 */
export const login = createAsyncThunk(
  'user/login',
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const response = await postLogin({ email, password })

      localStorage.setItem(LS_AUTH_TOKEN, response.data.authToken)

      return response.data
    } catch (e) {
      dispatch(showErrorMessage(e.response.data.message))
      return rejectWithValue(e.response.data.message)
    }
  }
)

export const logout = createAsyncThunk(
  'user/logout',
  async (args, { dispatch, rejectWithValue }) => {
    localStorage.removeItem(LS_AUTH_TOKEN)
    dispatch(clearOrganizations())
    dispatch(clearAssignments())
  }
)

/**
 * Register to API with provided credentials.
 */
export const register = createAsyncThunk(
  'user/register',
  async (newUser, { dispatch, rejectWithValue }) => {
    try {
      const response = await postUser(newUser)

      return response.data
    } catch (e) {
      dispatch(showErrorMessage(e.response.data.message))
      return rejectWithValue(e.response.data.message)
    }
  }
)

export const verify = createAsyncThunk(
  'user/verify',
  async ({ id, token }, { dispatch, rejectWithValue }) => {
    try {
      const response = await postVerifyUser(id, token)

      dispatch(showMessage("message-email-verified"))
      localStorage.setItem(LS_AUTH_TOKEN, response.data.authToken)

      return response.data
    } catch (e) {
      return rejectWithValue(e.message)
    }
  }
)

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (args, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken, id } = user

    if (!authToken) {
      return rejectWithValue("Authentication required")
    }
    try {
      const response = await postResetPassword(id, authToken)
      dispatch(showMessage("message-password-link-sent"))
      return response.data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  }
)

export const postNewPassword = createAsyncThunk(
  'user/postNewPassword',
  async ({ id, password, resetToken }, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken } = user

    if (!authToken) {
      return rejectWithValue("Authentication required")
    }

    try {
      const response = await postUserPassword(id, password, authToken, resetToken)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const update = createAsyncThunk(
  'user/update',
  async (updateRequest, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken } = user

    try {
      const {
        id,
        email,
        ...userData
      } = updateRequest
      const response = await putUser(id, userData, authToken)
      return response.data
    } catch (e) {
      return rejectWithValue(e.message)
    }
  }
)

export const init = createAsyncThunk(
  'user/init',
  async (args, { dispatch, rejectWithValue }) => {
    const authToken = localStorage.getItem(LS_AUTH_TOKEN)
    const cookieConsent = localStorage.getItem("cookieConsent")

    if (cookieConsent === "true") {
      dispatch(acceptCookies())
    }

    try {
      const { id } = jwt_decode(authToken)
      const response = await getUser(id, authToken)
      return { ...response.data, authToken }
    } catch (error) {
      if (error.message.includes("Invalid token specified")) {
        localStorage.removeItem(LS_AUTH_TOKEN)
        return { authToken: null }
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

/**
 * Redux state slice created with Redux toolkit.
 */
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    errors: [],
    loading: true,
    verifying: true
  },
  reducers: {
    addErrorMessage: (state, action) => ({ ...state, errors: state.errors.concat([action.payload]) }),
    removeErrorMessage: (state, action) => ({ ...state, errors: state.errors.filter(m => m !== action.payload) }),
    setAuth: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers: {
    [init.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      loading: false
    }),
    [init.rejected]: (state, action) => ({
      ...state,
      loading: false
    }),
    [register.pending]: (state, action) => ({ ...state, loading: true }),
    [register.fulfilled]: (state, action) => ({ ...state, loading: false }),
    [register.rejected]: (state, action) => ({ ...state, loading: false }),
    [login.pending]: (state, action) => ({ ...state, loading: true }),
    [login.fulfilled]: (state, action) => ({ ...state, ...action.payload, loading: false }),
    [login.rejected]: (state, action) => ({ ...state, loading: false }),
    [logout.fulfilled]: (state, action) => ({
      errors: state.errors,
      loading: state.loading,
      verifying: state.verifying
    }),
    [update.pending]: (state, action) => ({ ...state, loading: true }),
    [update.fulfilled]: (state, action) => ({
      ...state,
      ...action.payload,
      loading: false
    }),
    [update.rejected]: (state, action) => ({ ...state, loading: false }),
    [verify.pending]: (state, action) => ({ ...state, verifying: true }),
    [verify.fulfilled]: (state, action) => ({ ...state, ...action.payload, verifying: false }),
    [verify.rejected]: (state, action) => ({ ...state, verifying: false })
  }
})

export const { addErrorMessage, removeErrorMessage, setAuth } = userSlice.actions

export default userSlice.reducer
