import { Formik } from 'formik'
import React from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { Accordion, Button, Form, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import OrganizationsList from '../organization/OrganizationsList'
import { resetPassword } from '../../store/slices/userSlice'

import UserPasswordFields from './UserPasswordFields'

const UserPasswordResetLink = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const handleClick = e => {
    e.preventDefault()
    dispatch(resetPassword())
  }
  return <Link to="" onClick={handleClick}>{t("user-form-reset-password")}</Link>
}

const UserForm = ({ initialValues, newUser, loading, affiliations, onSubmit, submitText }) => {
  const { t } = useTranslation()

  const handleOnSubmit = formData => {
    const { email, password, confirmPassword, ...userData } = formData
    if (newUser) {
      onSubmit({ email, password, userData })
    } else {
      onSubmit({ email, userData })
    }
  }

  const validateForm = (values) => {
    const errors = {}

    if (!values.email) {
      errors.email = 'Email required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }

    if (newUser) {
      if (!values.password) {
        errors.password = 'Password required'
      } else if (!values.confirmPassword) {
        errors.confirmPassword = 'Password confirmation required'
      } else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match'
      }
    }

    return errors
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validate={validateForm}
    >
      {({
        values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Accordion>
            <Stack gap={5}>
              <Form.Group controlId="accountCredentials">
                <Stack gap={2}>
                  <Form.Label>{t("user-form-email-label")}</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={handleChange}
                    isInvalid={!!touched.email && !!errors.email}
                    isValid={touched.email && !errors.email}
                    value={values.email}
                    disabled={!newUser}
                  />
                  {errors.email && touched.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                  {newUser ?
                    <UserPasswordFields
                      values={values}
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                    /> : <UserPasswordResetLink />}
                </Stack>
              </Form.Group>
              <Form.Group controlId="userData">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>{t("user-form-personal-data-header")}</Accordion.Header>
                  <Accordion.Body>
                    <Stack gap={3}>
                      <Form.Label>{t("user-form-first-name-label")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        isInvalid={!!touched.firstName && !!errors.firstName}
                        isValid={touched.firstName && !errors.firstName}
                        value={values.firstName}
                      />
                      <Form.Label>{t("user-form-last-name-label")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        isInvalid={!!touched.lastName && !!errors.lastName}
                        isValid={touched.lastName && !errors.lastName}
                        value={values.lastName}
                      />
                    </Stack>
                  </Accordion.Body>
                </Accordion.Item>
              </Form.Group>
              <Form.Group controlId="userFormContact">
                <Accordion.Item eventKey="2">
                  <Accordion.Header>{t("user-form-contact-header")}</Accordion.Header>
                  <Accordion.Body>
                    <Stack gap={3}>
                      <Form.Label>{t("user-form-phone-label")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        onChange={handleChange}
                        isInvalid={!!touched.phone && !!errors.phone}
                        isValid={touched.phone && !errors.phone}
                        value={values.phone}
                      />
                    </Stack>
                  </Accordion.Body>
                </Accordion.Item>
              </Form.Group>
              {affiliations && affiliations.length > 0 && (
                <Form.Group controlId="userFormOrganizations">
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>{t("user-form-affiliations-header")}</Accordion.Header>
                    <Accordion.Body>
                      <OrganizationsList organizations={affiliations} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Form.Group>
              )}
              <Button type="submit" disabled={loading}>{t(submitText)}</Button>
            </Stack>
          </Accordion>
        </Form>
      )}
    </Formik>
  )
}

export default UserForm
