import React from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { Container, Nav, Navbar, Stack } from 'react-bootstrap'
import { Link } from "react-router-dom";

import "./DashboardPage.css"

export const DashboardSidePanel = () => {
  const { t } = useTranslation()
  const { id } = useSelector(state => state.user)
  return (
    <Navbar className="dashboard-navbar px-3 bg-light" collapseOnSelect expand="sm">
      <Navbar.Toggle className="w-100" aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse className="p-0" id="responsive-navbar-nav">
        <Nav>
          <Stack className="dashboard-nav py-3" gap={3}>
            <Nav.Link as={Link} to="/dashboard/websites">{t("websites")}</Nav.Link>
            <Nav.Link as={Link} to="/dashboard/assignments">{t("assignments")}</Nav.Link>
            <Nav.Link as={Link} to={`/users/${id}`}>{t("account")}</Nav.Link>
          </Stack>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

const DashboardPage = ({ pageComponent }) => {
  const { loading } = useSelector(state => state.user)
  return (
    <Container className="h-100 flex-row px-0" fluid>
      <DashboardSidePanel />
      <main className="h-100 col-lg-10 m-0 p-0 ms-sm-auto" style={{"background": "white"}}>
        {!loading && pageComponent}
      </main>
    </Container>
  )
}

export default DashboardPage
