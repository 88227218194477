import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container, Stack } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

import { fetchForAuthenticatedUser } from '../../store/slices/websitesSlice'

import WebsiteList from './WebsiteList'

const WebsitesPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchForAuthenticatedUser())
  }, [dispatch])

  return (
    <Container className="p-5">
      <Stack gap={5}>
        <h1 className="fw-light text-center">{t("websites-header")}</h1>
        <WebsiteList />
      </Stack>
    </Container>
  )
}

export default WebsitesPage
