import React from 'react'
import { useTranslation } from "react-i18next";
import { Alert, Button, Form, Stack } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { login } from '../../store/slices/userSlice'
import { setShowLoginModal } from '../../store/slices/appSlice.js'
import { fetchOrganizationsForAuthenticatedUser } from '../../store/slices/organizationsSlice'
import { fetchForAuthenticatedUser } from '../../store/slices/assignmentsSlice'

const LoginForm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { errors, loading } = useSelector(state => state.user)

  const handleSubmit = async e => {
    e.preventDefault()
    const result = await dispatch(login({ email: e.target.email.value, password: e.target.password.value }))
    const { requestStatus } = result.meta
    if (requestStatus === "fulfilled") {
      dispatch(setShowLoginModal(false))
      dispatch(fetchOrganizationsForAuthenticatedUser())
      dispatch(fetchForAuthenticatedUser())
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Stack gap={3}>
        <Form.Group controlId="loginFormEmail">
          <Form.Label>{t("user-form-email-label")}</Form.Label>
          <Form.Control
            required
            type="email"
            name="email"
            placeholder="Email"
          />
        </Form.Group>

        <Form.Group controlId="loginFormPassword">
          <Form.Label>{t("user-password-fields-password")}</Form.Label>
          <Form.Control
            required
            type="password"
            name="password"
            placeholder="Password"
          />
        </Form.Group>

        {errors.map((message, index) => <Alert key={index} variant="danger">{t(message)}</Alert>)}
        <Button disabled={loading} type="submit">{t("login-form-submit-text")}</Button>
      </Stack>
    </Form>
  )
}

export default LoginForm
