import React from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useLocation, useRouteMatch, Link, Redirect, Route, Switch } from 'react-router-dom'

import { getWebsiteCreatePhase } from '../../helpers/websiteHelper'

import WebsiteDistributionSection from './WebsiteDistributionSection'
import WebsiteSourcesForm from './WebsiteSourcesForm'
import WebsiteStatusSection from './WebsiteStatusSection'

const CreateWebsiteNavItem = ({ index, linkText, pathSuffix }) => {
  const { pathname } = useLocation()
  const { url } = useRouteMatch()
  const active = pathname.endsWith(pathSuffix)

  return (
    <Link className="ms-2 d-flex flex-row align-items-center mx-4" to={`${url}/${pathSuffix}`}>
      <span
        className="border me-2 border-primary rounded-circle d-flex align-items-center justify-content-center"
        style={{
          width: "32px",
          height: "32px",
          background: active ? "#0d6efd" : "white",
          color: active ? "white" : "black"
        }}
      >{index}</span>
      {linkText}
    </Link>
  )
}

const CreateWebsiteNavLine = ({ linkText, pathSuffix }) => (
  <svg height="10" width="100">
    <line x1="0" y1="5" x2="100" y2="5" strokeWidth="1" className="line" style={{ stroke: "gray" }}/>
  </svg>
)

const CreateWebsiteNav = () => (
  <Container className="d-flex flex-row align-items-center justify-content-center">
    <CreateWebsiteNavItem index={1} pathSuffix="upload" linkText="Upload" />
    <CreateWebsiteNavLine />
    <CreateWebsiteNavItem index={2} pathSuffix="build" linkText="Build" />
    <CreateWebsiteNavLine />
    <CreateWebsiteNavItem index={3} pathSuffix="publish" linkText="Publish" />
  </Container>
)

const CreateWebsite = ({ website }) => {
  const { url, path } = useRouteMatch()
  const { builds } = useSelector(state => state.websites)

  return (
    <Container className="p-5">
      <CreateWebsiteNav />
      <Switch>
        <Route exact path={`${path}/upload`}>
          <WebsiteSourcesForm id={website.id} />
        </Route>
        <Route exact path={`${path}/build`}>
          <WebsiteStatusSection website={website} />
        </Route>
        <Route exact path={`${path}/publish`}>
          <WebsiteDistributionSection website={website} />
        </Route>
        <Route exact path={`${path}`}>
          <Redirect to={`${url}/${getWebsiteCreatePhase(website, builds)}`} />
        </Route>
      </Switch>
    </Container>
  )
}

export default CreateWebsite
