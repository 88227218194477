import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'

import { fetchForAuthenticatedUser } from '../../store/slices/assignmentsSlice.js'

import AssignmentList from './AssignmentList'

const AssignmentsPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { authToken } = useSelector(state => state.user)
  const { assignments } = useSelector(state => state.assignments)

  useEffect(() => {
    if (authToken && !assignments) {
      dispatch(fetchForAuthenticatedUser())
    }
  }, [dispatch, authToken, assignments])

  return (
    <Container className="p-5">
      <h1 className="fw-light text-center mb-5">{t("assignments")}</h1>
      <AssignmentList />
    </Container>
  )
}

export default AssignmentsPage
