const websiteIsCreated = (website) => !!website.distribution // && website.distribution.status === "Deployed"

const getWebsiteCreatePhase = (website, builds) => {
  if (!website.sourceUploaded) {
    return "upload"
  } else if (!builds.find(b => b.websiteId === website.id && b.buildStatus === "SUCCEEDED" )) {
    return "build"
  } else if (!website.distributionId){
    return "publish"
  }

  return ""
}

export {
  getWebsiteCreatePhase,
  websiteIsCreated
}
