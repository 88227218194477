import { Accordion, Button, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next"

const WebsiteInformationSection = ({ handleDelete }) => {
  const { t } = useTranslation()

  return (
    <>
      <h3 className="fw-light">{t("website-information-header")}</h3>
      <Accordion>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t("website-information-management-header")}</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Button variant="danger" onClick={handleDelete}>{t("website-information-management-delete-text")}</Button>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  )
}

export default WebsiteInformationSection
