import React from 'react'

import { Container } from 'react-bootstrap'

const NewsFeed = ({ header, hashtags, brief, link }) => {
  return (
    <Container className="p-4 justify-content-between" fluid>
      <h2 className="fw-light mb-5">{header}</h2>
      <p className="lead">{brief}</p>
    </Container>
  )
}

export default NewsFeed
