import React, { useState } from 'react'
import { Formik } from 'formik'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'
import { Accordion, Button, Form, Stack } from 'react-bootstrap'

import { deleteOrganization } from '../../store/slices/organizationsSlice'

const OrganizationForm = ({ initialValues, onSubmit, submitText }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [ changed, setChanged ] = useState(false)

  const handleOnSubmit = (o) => {
    onSubmit && onSubmit(o)
    setChanged(false)
  }

  const validateForm = (values) => {
    const errors = {}

    if (!values.name) {
      errors.name = 'Name required'
    }

    if (!values.vatID) {
      errors.vatID = 'Vat ID required'
    } else if (!/^[1234567890]+-[1234567890]$/i.test(values.vatID)) {
      errors.vatID = 'Vat ID must be in form XXXXXXX-X'
    }

    return errors
  }

  return (
    <Formik
      initialValues={initialValues || {name:"", vatID: "", id: ""}}
      onSubmit={handleOnSubmit}
      validate={validateForm}
    >
      {({
        values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Accordion>
            <Stack gap={5}>
              <Stack gap={3}>
                <Form.Group>
                  <Form.Label>{t("organization-form-vatid-label")}</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={!!values.id}
                    name="vatID"
                    onChange={e => {setChanged(true); handleChange(e)}}
                    isInvalid={!!touched.vatID && !!errors.vatID}
                    isValid={touched.vatID && !errors.vatID}
                    value={values.vatID}
                    placeholder={t("organization-form-vatid-placeholder")}
                  />
                  {errors.vatID && touched.vatID && <Form.Control.Feedback type="invalid">{errors.vatID}</Form.Control.Feedback>}
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t("organization-form-name-label")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={e => {setChanged(true); handleChange(e)}}
                    isInvalid={!!touched.name && !!errors.name}
                    isValid={touched.name && !errors.name}
                    value={values.name}
                    placeholder={t("organization-form-name-placeholder")}
                  />
                  {errors.name && touched.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
                </Form.Group>
              </Stack>
              {values.id && (
                <Form.Group>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>{t("organization-form-management-label")}</Accordion.Header>
                    <Accordion.Body>
                      <Stack gap={3}>
                        <Form.Group>
                          <Button variant="danger" onClick={e => dispatch(deleteOrganization(values.id))}>
                            {t("organization-form-management-delete-label")}
                          </Button>
                        </Form.Group>
                      </Stack>
                    </Accordion.Body>
                  </Accordion.Item>
                </Form.Group>
              )}
              {changed &&<Button type="submit" variant="primary">{submitText}</Button>}
            </Stack>
          </Accordion>
        </Form>
      )}
    </Formik>
  )
}

export default OrganizationForm
