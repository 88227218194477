import React from 'react'
import { Container, Stack, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'

const OrganizationListItem = ({ organizationData }) => {
  const { id, name, vatID } = organizationData
  return (
    <li className="list-group-item">
      <div className="d-flex w-100 justify-content-between">
        <Link to={`/dashboard/organizations/${id}`}>{name}</Link>
        <label>{vatID}</label>
      </div>
    </li>
  )
}

const OrganizationsList = () => {
  const { organizations, loading } = useSelector(state => state.organizations)
  const { t } = useTranslation()

  if (loading) {
    return (
      <>
        <Spinner animation="border" size="sm" /> {t("organizations-page-fetching")}
      </>
    )
  }

  return (
    <Stack gap={3}>
      {organizations.length > 0 ? (
        <>
          <ul className="list-group">
            {organizations.map(organization => <OrganizationListItem key={organization.id} organizationData={organization} />)}
          </ul>
          <Link className="ps-3" to="/dashboard/organizations/create">{t("organizations-list-register")}</Link>
        </>
      ) : (
        <Container className="text-center">
          {t("organizations-list-no-organizations")} <Link to="/dashboard/organizations/create">{t("organizations-list-register")}</Link>
        </Container>
      )}
    </Stack>
  )
}

export default OrganizationsList
