import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080'

export const postUser = async userData => await axios.post(API_URL + '/user', userData)

export const postVerifyUser = async (id, token) => await axios.post(API_URL + `/user/${id}/verify?token=${token}`)

export const getUser = async (userId, authToken) => await axios.get(
  API_URL + `/user/${userId}`,
  {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  }
)

export const putUser = async (userId, userData, authToken) => await axios.put(
  API_URL + `/user/${userId}`,
  userData,
  {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  }
)

export const postResetPassword = async (userId, authToken) => await axios.post(
  API_URL + `/user/${userId}/reset-password`,
  {},
  {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  }
)

export const postUserPassword = async (userId, password, authToken, resetToken) => await axios.post(
  API_URL + `/user/${userId}/password`,
  { password, resetToken },
  {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  }
)
