import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Container, Form, Stack } from 'react-bootstrap'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from "react-i18next"

import { uploadWebsiteSource } from '../../store/slices/websitesSlice'

const WebsiteSourcesForm = ({ id }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { url } = useRouteMatch()
  const { t } = useTranslation()

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (e.target.source.files.length > 0) {
      const { meta } = await dispatch(uploadWebsiteSource({
        id,
        source: e.target.source.files[0]
      }))
      if (meta.requestStatus === "fulfilled") {
        const pathVars = url.split("/")
        let targetUrl = ""
        for (let i = 0; i < pathVars.length - 1; i++) {
          targetUrl += pathVars[i] + "/"
        }

        history.push(`${targetUrl}build`)
      }
    }
  }

  return (
    <Container className="p-3">
      <h3 className="fw-light mb-5">Sources</h3>
      <Form onSubmit={handleSubmit}>
        <Stack gap={3}>
          <Form.Group>
            <Form.Label>{t("website-form-information-sources-zip-label")}</Form.Label>
            <Form.Control name="source" type="file" accept=".zip" placeholder="test" />
          </Form.Group>
          <Button variant="primary" type="submit">{t("websitesources-form-create-submit-text")}</Button>
        </Stack>
      </Form>
    </Container>
  )
}

export default WebsiteSourcesForm
