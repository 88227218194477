import i18n from "i18next"
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from "react-i18next"
import { translationEn, translationFi } from './translations'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translationEn },
      fi: { translation: translationFi },
    },
    fallbackLng: "fi",
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
