import React from 'react'
import { useTranslation } from "react-i18next"

import Bar from 'react-plotly.js'

const timeSeriesToGraphData = (timestamps, values) => {
  const minDate = new Date(timestamps.at(-1))
  const maxDate = new Date(timestamps.at(0))
  const x = []
  const y = []
  let dateString = null
  let dataPointIndex = null
  for (var d = new Date(minDate); d <= maxDate; d.setTime(d.getTime()+1000*60*60)) {
    dateString = new Date(d).toISOString()
    x.push(dateString)
    dataPointIndex = timestamps.findIndex(t => t === dateString)
    if (dataPointIndex === -1) {
      y.push(0)
    } else {
      y.push(values.at(dataPointIndex))
    }
  }
  return { x, y }
}

const formatTotal = (title, total) => {
  if (title === "BytesDownloaded") {
    return {
      value: total / 1000000.0,
      scale: "M",
      unit: "B"
    }
  }
  return {
    value: total,
    scale: "",
    unit: ""
  }
}

const WebsiteMetricsChart = ({ title, timestamps, values }) => {
  const { t } = useTranslation()

  const total = values.reduce((s,a) => s+a, 0)
  const { x, y } = timeSeriesToGraphData(timestamps, values)

  const { value, scale, unit } = formatTotal(title, total)
  return (
    <Bar
      data={[
        {
          x,
            y,
            type: 'scatter',
            mode: 'lines+markers',
        }
      ]}
      layout={{
        autosize: false,
        title: `${t(title)} (${value} ${scale}${t(unit)} ${t("website-metrics-total")})`,
        yaxis: {
          rangemode: 'tozero'
        }
      }}
      config={{responsive: true }}
    />
  )
}

export default WebsiteMetricsChart
