import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next"
import { Container, Spinner, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const AssignmentListItem = ({ assignment }) => {
  const { id, updatedAt, assignmentData } = assignment
  const { title } = assignmentData
  return (
    <li className="list-group-item">
      <div className="d-flex w-100 justify-content-between">
        <Link to={`/dashboard/assignments/${id}`}>{title}</Link>
        <label>{new Date(updatedAt).toLocaleDateString()}</label>
      </div>
    </li>
  )
}

const AssignmentList = () => {
  const { t } = useTranslation()
  const { assignments, loading } = useSelector(state => state.assignments)

  if (loading) {
    return (
      <>
        <Spinner animation="border" size="sm" /> {t("assignments-page-fetching")}
      </>
    )
  }

  return (
    <Stack gap={3}>
      {assignments.length > 0 ? (
        <ul className="list-group">
          {assignments.map(assignment => <AssignmentListItem key={assignment.id} assignment={assignment} />)}
        </ul>
      ) : (
        <Container>{t("assignment-list-no-assignments")}</Container>
      )}
      <Link className="ps-3" to="/dashboard/assignments/create">{t("assignment-list-create")}</Link>
    </Stack>
  )
}

export default AssignmentList
