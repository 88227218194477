import React from 'react'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
import { Container } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom'

import { update } from '../../store/slices/userSlice'

import UserForm from './UserForm'

const ShowUser = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { id } = useParams()
  const { errors, loading, ...user } = useSelector(state => state.user)
  const { organizations } = useSelector(state => state.organizations)

  if (loading) {
    return null
  } else if (id !== user.id) {
    return <Redirect to="/" />
  }

  return (
    <Container className="p-5">
      <h1 className="text-center">{t("show-user-header")}</h1>
      <UserForm
        initialValues={{ email: user.email, ...user.userData }}
        changePassword={false}
        loading={loading}
        errors={errors}
        affiliations={organizations}
        onSubmit={(userData) => dispatch(update({ ...userData, id: user.id }))}
        submitText={t("show-user-submit-text")}
      />
    </Container>
  )
}

export default ShowUser
