import FormData from 'form-data'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  getWebsiteBuilds,
  getWebsiteMetrics,
  fetchWebsitesForUser,
  postWebsite,
  postWebsiteBuild,
  putWebsiteSource,
  sendDeleteWebsite,
  postWebsiteDistribution
} from '../../api/websiteAPI'

export const publishWebsite = createAsyncThunk(
  'websites/publishWebsite',
  async (id, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken } = user

    try {
      const response = await postWebsiteDistribution(id, authToken)
      return response.data
    } catch (error) {
      return rejectWithValue({message: error.message})
    }
  }
)

export const fetchWebsiteBuilds = createAsyncThunk(
  'websites/fetchWebsiteBuilds',
  async (id, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken } = user

    try {
      const response = await getWebsiteBuilds(id, authToken)
      return response.data
    } catch (error) {
      return rejectWithValue({message: error.message})
    }
  }
)

export const fetchWebsiteMetrics = createAsyncThunk(
  'websites/fetchWebsiteMetrics',
  async (id, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken } = user

    try {
      const response = await getWebsiteMetrics(id, authToken)
      return response.data
    } catch (error) {
      return rejectWithValue({message: error.message})
    }
  }
)

/**
 * Fetch assignments for the authenticated user.
 */
export const fetchForAuthenticatedUser = createAsyncThunk(
  'websites/fetchForAuthenticatedUser',
  async (args, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { id, authToken } = user

    try {
      const response = await fetchWebsitesForUser(id, authToken)
      return response.data
    } catch (error) {
      return rejectWithValue({message: error.message})
    }
  }
)

export const createWebsite = createAsyncThunk(
  'websites/createWebsite',
  async (requestBody, { dispatch, getState, rejectWithValue }) => {
    const { user: { authToken } } = getState()

    try {
      const response = await postWebsite(requestBody, authToken)
      return response.data
    } catch (error) {
      return rejectWithValue({ error: error.message })
    }
  }
)

export const uploadWebsiteSource = createAsyncThunk(
  'websites/uploadWebsiteSource',
  async ({ id, source }, { dispatch, getState, rejectWithValue }) => {
    const { user: { authToken } } = getState()
    const bodyFormData = new FormData()
    bodyFormData.append('source', source)

    try {
      const response = await putWebsiteSource(id, bodyFormData, authToken)
      return response.data
    } catch (error) {
      return rejectWithValue({ error: error.message })
    }
  }
)

export const startWebsiteBuild = createAsyncThunk(
  'websites/startWebsiteBuild',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    const { user: { authToken } } = getState()

    try {
      const response = await postWebsiteBuild(id, authToken)
      return response.data
    } catch (error) {
      return rejectWithValue({ error: error.message })
    }
  }
)


export const deleteWebsite = createAsyncThunk(
  'websites/deleteWebsite',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    const { user: { authToken } } = getState()

    try {
      const response = await sendDeleteWebsite(id, authToken)
      return response.data
    } catch (error) {
      return rejectWithValue({ error: error.message })
    }
  }
)

export const websitesSlice = createSlice({
  name: 'websites',
  initialState: {
    loading: true,
    websites: [],
    builds: [],
    metrics: []
  },
  reducers: {
    setLoading: (state, action) => ({ ...state, loading: action.payload }),
  },
  extraReducers: {
    [createWebsite.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [createWebsite.fulfilled]: (state, action) => ({
      ...state,
      websites: state.websites.concat([action.payload]),
      loading: false
    }),
    [createWebsite.rejected]: (state, action) => ({
      ...state,
      loading: false
    }),
    [uploadWebsiteSource.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [uploadWebsiteSource.fulfilled]: (state, action) => ({
      ...state,
      websites: state.websites.map(w => w.id === action.payload.id ? ({ ...w, ...action.payload }) : w),
      loading: false
    }),
    [uploadWebsiteSource.rejected]: (state, action) => ({
      ...state,
      loading: false
    }),
    [fetchForAuthenticatedUser.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [fetchForAuthenticatedUser.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      websites: action.payload.websites
    }),
    [fetchForAuthenticatedUser.rejected]: (state, action) => ({
      ...state,
      loading: false
    }),
    [fetchWebsiteBuilds.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [startWebsiteBuild.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [startWebsiteBuild.fulfilled]: (state, action) => ({
      ...state,
      builds: state.builds.concat([action.payload]),
      loading: false
    }),
    [startWebsiteBuild.rejected]: (state, action) => ({
      ...state,
      loading: false
    }),
    [fetchWebsiteBuilds.fulfilled]: (state, action) => ({
      ...state,
      builds: action.payload,
      loading: false
    }),
    [fetchWebsiteBuilds.rejected]: (state, action) => ({
      ...state,
      loading: false
    }),
    [publishWebsite.pending]: (state,action) => ({
      ...state,
      loading: true
    }),
    [publishWebsite.fulfilled]: (state,action) => ({
      ...state,
      websites: state.websites.map(w => w.id === action.payload.id ? action.payload : w),
      loading: false
    }),
    [publishWebsite.rejected]: (state,action) => ({
      ...state,
      loading: false
    }),
    [fetchWebsiteMetrics.pending]: (state,action) => ({
      ...state,
      loading: true
    }),
    [fetchWebsiteMetrics.fulfilled]: (state,action) => ({
      ...state,
      metrics: state.metrics.find(m => m.websiteId === action.payload.websiteId) ? (
        state.metrics.map(m => m.websiteId === action.payload.websiteId ? action.payload : m)
      ) : (
        state.metrics.concat([action.payload])
      ),
      loading: false
    }),
    [fetchWebsiteMetrics.rejected]: (state,action) => ({
      ...state,
      loading: false
    }),
    [deleteWebsite.pending]: (state,action) => ({
      ...state,
      loading: true
    }),
    [deleteWebsite.fulfilled]: (state,action) => ({
      ...state,
      websites: state.websites.filter(w => w.id !== action.payload.id),
      metrics: state.metrics.filter(m => m.websiteId !== action.payload.id),
      loading: false
    }),
    [deleteWebsite.rejected]: (state,action) => ({
      ...state,
      loading: false
    }),
  }
})

export const { setLoading } = websitesSlice.actions

export default websitesSlice.reducer
