import { configureStore } from '@reduxjs/toolkit'
import { assignmentsReducer, appReducer, userReducer, organizationsReducer, websitesReducer } from './slices'

export default configureStore({
  reducer: {
    assignments: assignmentsReducer,
    appControl: appReducer,
    user: userReducer,
    organizations: organizationsReducer,
    websites: websitesReducer
  }
})
