import React from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import "../pages/page.css"

const AppFooter = () => {
  const { t } = useTranslation()
  const { cookieConsent, readingPrivacyPolicy } = useSelector(state => state.appControl)

  return (
    <Container
      className={`border-top bg-light ${!cookieConsent && readingPrivacyPolicy ? "show-cookie-consent" : ""} py-4`}
      fluid
    >
      <footer
        className="row mx-4 justify-content-between"
      >
        <Col>
          <p className="my-1">
            © 2023 AnteronWeb · <Link to="/privacy">{t("privacy")}</Link>
          </p>
        </Col>
        <Col>
          <p className="float-end my-1">
            <a href="#top">{t("footer-back-to-top")}</a>
          </p>
        </Col>
      </footer>
    </Container>
  )
}

export default AppFooter
