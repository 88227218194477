import React from 'react'
import { Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'

import { updateForAuthenticatedUser } from '../../store/slices/organizationsSlice'

import OrganizationForm from './OrganizationForm'

const ShowOrganization = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { organizations, loading } = useSelector(state => state.organizations)
  const { id } = useParams()

  const organization = organizations.find(o => o.id === id)

  const handleSubmit = (organization) => {
    dispatch(updateForAuthenticatedUser(organization))
  }

  if (loading === true) {
    return null
  } else if (organization === undefined) {
    return <Redirect to="/dashboard/organizations" />
  }

  return (
    <Container className="p-5 justify-content-between">
      <h4 className="fw-light text-center">{t("organization")}</h4>
      <OrganizationForm
        initialValues={organization}
        onSubmit={handleSubmit}
        submitText={t("show-organization-submit-text")}
      />
    </Container>
  )
}

export default ShowOrganization
