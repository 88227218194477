import React from 'react'
import { useTranslation } from "react-i18next";
import { Container } from 'react-bootstrap'

import HostingPricingTable from './HostingPricingTable'

const HostingPricingSection = () => {
  const { t } = useTranslation()
  return (
    <Container className="p-5">
      <h2 className="fw-light text-center">{t("hosting-page-pricing-header")}</h2>
      <Container className="m-3">{t("hosting-page-pricing-body")}</Container>
      <HostingPricingTable />
    </Container>
  )
}

export default HostingPricingSection
