import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Stack } from 'react-bootstrap'

import { showMessage, setShowLoginModal } from '../../store/slices/appSlice'
import { register } from '../../store/slices/userSlice'

import LoginForm from '../login/LoginForm'
import UserForm from '../user/UserForm'

const LoginModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { loading } = useSelector(state => state.user)
  const { cookieConsent, showLoginModal } = useSelector(state => state.appControl)
  const [activeForm, setActiveForm] = useState('login')

  const handleCreateUser = async (newUser) => {
    const result = await dispatch(register(newUser))
    const { requestStatus } = result.meta
    if (requestStatus === "fulfilled") {
      dispatch(setShowLoginModal(false))
      dispatch(showMessage("message-email-verification-sent"))
    }
  }

  if (!cookieConsent) {
    return null
  }

  return (
    <Modal centered show={showLoginModal} onHide={() => dispatch(setShowLoginModal(false))}>
      <Modal.Header closeButton>
        <Stack direction="horizontal" gap={3}>
          <span onClick={ e => setActiveForm('login') } style={{"cursor": "pointer"}}>
            {t("login-modal-login")}
          </span>
          <span className="vr" />
          <span onClick={ e => setActiveForm('register') } style={{"cursor": "pointer"}}>
            {t("login-modal-register")}
          </span>
        </Stack>
      </Modal.Header>
      <Modal.Body>
        {activeForm === 'login' ?
          <LoginForm /> :
          <UserForm
            initialValues={{}}
            newUser={true}
            loading={loading}
            onSubmit={handleCreateUser}
            submitText={"user-form-register"}
          />}
      </Modal.Body>
    </Modal>
  )
}

export default LoginModal
