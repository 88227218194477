import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import { Container, Spinner } from 'react-bootstrap';
import { useParams, Redirect } from 'react-router-dom'

import AssignmentForm from './AssignmentForm'

import { fetchForAuthenticatedUser, updateForAuthenticatedUser } from '../../store/slices/assignmentsSlice.js'

const ShowAssignment = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { assignments, loading: assignmentLoading } = useSelector(state => state.assignments)
  const { loading: userLoading } = useSelector(state => state.user)
  const { id } = useParams()

  useEffect(() => {
    if (!assignments.find(a => a.id === id)) {
      dispatch(fetchForAuthenticatedUser())
    }
  }, [assignments, id, dispatch])

  const assignment = assignments.find(a => a.id === id)

  if (userLoading || assignmentLoading) {
    return <Spinner animation="border" size="sm" />
  } else if (assignment === undefined) {
    return <Redirect to="/dashboard/assignments" />
  }

  const { assignmentData, ...assignmentMeta } = assignment

  return (
    <Container className="p-5 flex-column justify-content-between">
      <AssignmentForm
        initialValues={{ ...assignmentData, ...assignmentMeta }}
        submit={a => dispatch(updateForAuthenticatedUser(a))}
        submitText={t("show-assignment-submit-text")}
      />
    </Container>
  )
}

export default ShowAssignment
