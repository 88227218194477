import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080'

export const fetchOrganizationsForUser = async (userId, authToken) => await axios.get(
  API_URL + `/user/${userId}/organization`,
  {
    headers: {
      'Authorization': `Bearer ${authToken}` }
  }
)

export const postOrganization = async (organization, authToken) => await axios.post(
  API_URL + `/organization`,
  organization,
  {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  }
)

export const putOrganizationsForUser = async (organization, authToken) => await axios.put(
  API_URL + `/organization/${organization.id}`,
  organization,
  {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  }
)
export const sendDeleteOrganization = async (organizationId, authToken) => await axios.delete(
  API_URL + `/organization/${organizationId}`,
  {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  }
)
