import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080'

export const getWebsiteBuilds = (id, authToken) => axios.get(
  API_URL + `/website/${id}/build`,
  {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  }
)

export const fetchWebsitesForUser = async (userId, authToken) => {
  return await axios.get(
    API_URL + `/user/${userId}/website`,
    {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    }
  )
}

export const postWebsite = async (requestBody, authToken) => {
  return await axios.post(
    API_URL + '/website',
    requestBody,
    {
      headers: {
        'Authorization': `Bearer ${authToken}`,
      }
    }
  )
}

export const putWebsiteSource = async (id, file, authToken) => {
  return await axios.put(
    API_URL + `/website/${id}/sources`,
    file,
    {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data"
      }
    }
  )
}

export const postWebsiteBuild = async (id, authToken) => axios.post(
  API_URL + `/website/${id}/build`,
  {},
  {
    headers: {
      'Authorization': `Bearer ${authToken}`,
    }
  }
)

export const sendDeleteWebsite = (id, authToken) => {
  return axios.delete(
    API_URL + `/website/${id}`,
    {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    }
  )
}

export const getWebsiteMetrics = async (websiteId, authToken) => {
  return await axios.get(
    API_URL + `/website/${websiteId}/metrics`,
    {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    }
  )
}

export const postWebsiteDistribution = async (websiteId, authToken) => {
  return await axios.post(
    API_URL + `/website/${websiteId}/distribution`,
    {},
    {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    }
  )
}

