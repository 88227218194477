import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080'

export const fetchAssignments = async authToken => await axios.get(
  API_URL + '/assignment',
  {
    headers: {
      'Authorization': `Bearer ${authToken}` }
  }
)

export const fetchAssignmentForUser = async (userId, authToken) => await axios.get(
  API_URL + `/user/${userId}/assignment`,
  {
    headers: {
      'Authorization': `Bearer ${authToken}` }
  }
)

export const updateAssignmentForUser = async (userId, updatedAssignment, authToken) => await axios.put(
  API_URL + `/assignment/${updatedAssignment.id}`,
  updatedAssignment,
  {
    headers: {
      'Authorization': `Bearer ${authToken}` }
  }
)

export const postAssignment = async (assignment, authToken) => await axios.post(
  API_URL + `/assignment`,
  assignment,
  {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  }
)
export const sendDeleteAssignment = async (assignmentId, authToken) => await axios.delete(
  API_URL + `/assignment/${assignmentId}/`,
  {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  }
)
