import React from 'react'
import { Carousel } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

import NewsFeed from './NewsFeed'

const frontPageItems = [
  {
    id: 1,
    header: "carousel-hosting-header",
    brief: "carousel-hosting-brief",
    link: "/hosting"
  },
  {
    id: 2,
    header: "carousel-consulting-header",
    brief: "carousel-consulting-brief",
    link: "/consulting"
  }
]

const FrontCarousel = () => {
  const { t } = useTranslation()
  return (
    <Carousel className="bg-dark bg-gradient" style={{ height: "28em" }}>
    {frontPageItems.map(item => (
      <Carousel.Item key={item.id}>
        <Carousel.Caption className="text-center h-75">
          <NewsFeed
            header={t(item.header)}
            brief={t(item.brief)}
            link={item.link}
          />
        </Carousel.Caption>
      </Carousel.Item>
    ))
    }
  </Carousel>
)
}

export default FrontCarousel
