import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Container, Row } from 'react-bootstrap'

import { publishWebsite } from '../../store/slices/websitesSlice'

const WebsiteDistributionSection = ({ website }) => {
  const dispatch = useDispatch()
  const { distribution } = website

  const handlePublish = (event) => {
    dispatch(publishWebsite(website.id))
  }

  return (
    <Container className="p-3">
      <h3 className="fw-light mb-5">Distribution</h3>
      <Row>
        {!!distribution && !!distribution.id ? (
          <span>{distribution.id}</span>
        ) : (
          <Button onClick={handlePublish}>Publish</Button>
        )}
      </Row>
    </Container>
  )
}

export default WebsiteDistributionSection
