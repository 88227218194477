import React from 'react'
import { useTranslation } from "react-i18next";
import { Col, Container, ListGroup, Row } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fa1, fa2, fa3 } from '@fortawesome/free-solid-svg-icons'

import './OverviewSection.css'

const indexIcons = [ fa1, fa2, fa3 ]

const OpportunityListItem = ({ index, text }) => {
  const { t } = useTranslation()

  return (
    <ListGroup.Item className="d-flex">
      <span className="text-center" style={{width: "1em"}}>
        <FontAwesomeIcon className="m-auto" icon={indexIcons[index]} />{' '}
      </span>
      <Container fluid>{t(text)}</Container>
    </ListGroup.Item>
  )
}

const OverviewSection = () => {
  const { t } = useTranslation()

  return (
    <Container className="p-3">
      <h1 className="fw-light text-center">{t("overview-header")}</h1>
      <Row className="my-5">
        <Col className="text-center lead m-3 overview-row-content my-auto">
          {t("overview-body")}{' '}
        </Col>
        <Col className="m-3 overview-row-filler">
          <ListGroup variant="flush">
            <OpportunityListItem index={0} text="overview-opportunities-item-1" />
            <OpportunityListItem index={1} text="overview-opportunities-item-2" />
            <OpportunityListItem index={2} text="overview-opportunities-item-3" />
          </ListGroup>
        </Col>
      </Row>
      <Container>
      </Container>
    </Container>
  )
}

export default OverviewSection
