import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Redirect } from 'react-router-dom'

import { verify } from '../../store/slices/userSlice'
import { useQuery } from '../../hooks'

const UserVerification = () => {
  const dispatch = useDispatch()
  const { verifying, authToken } = useSelector(state => state.user)
  const query = useQuery()
  const { id } = useParams()

  useEffect(() => {
    dispatch(verify({ id, token: query.get("token") }))
  }, [dispatch, id, query])

  if (verifying) {
    return (
      <Spinner />
    )
  }

  if (authToken) {
    return (
      <Redirect to="/dashboard" />
    )
  }

  return (
    <Redirect to="/" />
  )
}

export default UserVerification
