import React from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'

import { Container, Nav, Navbar, NavDropdown, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import i18n from '../../lang'

import { UserDropdown } from '../user'

import './AppNav.css'

const AppNav = () => {
  const { t } = useTranslation()
  const { authToken } = useSelector(state => state.user)

  const handleLanguageClick = (lng) => (e) => {
    e.preventDefault()
    i18n.changeLanguage(lng)
  }

  return (
    <Container className="border-bottom p-0" fluid>
      <header className="p-3">
        <Navbar className="p-0" collapseOnSelect expand="sm">
          <Navbar.Brand className="col-lg-2 ps-2">
            <Link to="/">
              AnteronWeb
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse className="app-nav mx-1 p-0 justify-content-between">
            <Nav className="w-100">
              <Stack className="app-nav w-100" direction="horizontal" gap={3}>
                {authToken && (
                  <Nav.Link as={Link} to="/dashboard" eventKey="1">
                    {t("dashboard")}
                  </Nav.Link>
                )}
                <NavDropdown title={t("nav-services")}>
                  <NavDropdown.Item>
                    <Nav.Link as={Link} to="/hosting" eventKey="2">
                      {t("nav-hosting")}
                    </Nav.Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <Nav.Link as={Link} to="/consulting" eventKey="3">
                      {t("nav-consulting")}
                    </Nav.Link>
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/privacy" eventKey="1">
                  {t("privacy")}
                </Nav.Link>
                <div style={{"flexGrow": "1"}} />
                <div className="p-2">
                  <Link to="" onClick={handleLanguageClick("fi")}>fi</Link> / <Link to="" onClick={handleLanguageClick("en")}>en</Link>
                </div>
                <UserDropdown />
              </Stack>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </Container>
  )
}

export default AppNav
