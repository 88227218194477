import React from 'react'
import { useTranslation } from "react-i18next";
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { Button, Container, Form, Stack } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'

import { useQuery } from '../../hooks'

import { postNewPassword } from '../../store/slices/userSlice'
import { showMessage } from '../../store/slices/appSlice'
import UserPasswordFields from './UserPasswordFields'

const UserPasswordReset = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const { id } = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  const handleOnSubmit = async credentials => {
    console.log(credentials)
    const result = await dispatch(postNewPassword({ id, password: credentials.password, resetToken: query.get("token") }))
    const { requestStatus } = result.meta
    if (requestStatus === "fulfilled") {
      dispatch(showMessage("message-password-changed"))
      history.push("/dashboard")
    }
  }

  const validateForm = (values) => {
    const errors = {}

    if (!values.password) {
      errors.password = 'Password required'
    } else if (!values.confirmPassword) {
      errors.confirmPassword = 'Password confirmation required'
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match'
    }

    return errors
  }

  return (
    <Container>
      <h1 className="text-center">{t("user-password-reset-header")}</h1>
    <Formik
      initialValues={{ password: "", confirmPassword: "" }}
      onSubmit={handleOnSubmit}
      validate={validateForm}
    >
      {({
        values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
      }) => (
      <Form onSubmit={handleSubmit}>
        <Stack gap={3}>
          <UserPasswordFields errors={errors} touched={touched} handleChange={handleChange} values={values} />
          <Button variant="primary" type="submit">{t("user-password-reset-submit-text")}</Button>
        </Stack>
      </Form>
      )}
    </Formik>
    </Container>
  )
}

export default UserPasswordReset
