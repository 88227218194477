import React from 'react'
import { useTranslation } from "react-i18next";
import { Table } from 'react-bootstrap'

const HostingPricingTable = () => {
  const { t } = useTranslation()
  return (
    <Table striped bordered hover className="text-center">
      <thead>
        <tr>
          <th>{t("hosting-pricing-table-cost-header")}</th>
          <th>{t("hosting-pricing-table-price-header")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t("hosting-pricing-table-downloads-label")}</td>
          <td>0.01 €/G{t("unit-bytes")}</td>
        </tr>
        <tr>
          <td>{t("hosting-pricing-table-code-build-label")}</td>
          <td>0.01 €/min</td>
        </tr>
        <tr>
          <td>{t("hosting-pricing-table-storage-label")}</td>
          <td>0.03 €/G{t("unit-bytes")}</td>
        </tr>
      </tbody>
    </Table>
  )
}

export default HostingPricingTable
