import React from 'react'
import { useSelector } from 'react-redux'
import { Container, Spinner, Stack } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WebsiteListItem = ({ website }) => {
  const { t } = useTranslation()

  const { id, alias, distributionStatus } = website
  return (
    <li className="list-group-item">
      <div className="d-flex w-100 justify-content-between">
        <Link to={`/dashboard/websites/${id}`}>{alias}</Link>
        <label>{t(distributionStatus)}</label>
      </div>
    </li>
  )
}

const WebsiteList = () => {
  const { t } = useTranslation()
  const { loading, websites } = useSelector(state => state.websites)

  if (loading) {
    return (
      <Spinner animation="border" size="sm" />
    )
  }

  return (
    <Stack gap={3}>
      {websites.length > 0 ? (
        <ul className="list-group">
          {websites.map(website => <WebsiteListItem key={website.id} website={website} />)}
        </ul>
      ) : (
        <Container>{t("website-list-no-assignments")}</Container>
      )}
      <Link className="ps-3" to="/dashboard/websites/create">{t("websites-create-link")}</Link>
    </Stack>
  )
}

export default WebsiteList
