import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Stack } from 'react-bootstrap';

import { register } from '../../store/slices/organizationsSlice.js'
import { setShowRegisterOrganizationModal } from '../../store/slices/appSlice.js'

import OrganizationForm from './OrganizationForm'

const OrganizationModal = () => {
  const dispatch = useDispatch()
  const { showRegisterOrganizationModal } = useSelector(state => state.appControl)
  const [ organization, setOrganization ] = useState({ name: "", vatID: "" })

  return (
    <Modal centered show={showRegisterOrganizationModal} onHide={() => dispatch(setShowRegisterOrganizationModal(false))}>
      <Modal.Header closeButton>
        <Stack direction="horizontal" gap={3}>
          <span>
            Organization
          </span>
        </Stack>
      </Modal.Header>
      <Modal.Body>
        <OrganizationForm
          organization={organization}
          onUpdate={(o) => setOrganization({ ...o, changed: true })}
          onSubmit={(o) => dispatch(register(o))}
          submitText="Register Organization"
        />
      </Modal.Body>
    </Modal>
  )
}

export default OrganizationModal
