import React from 'react'
import { useTranslation } from "react-i18next";
import { Container } from 'react-bootstrap'

const HostingOverviewSection = () => {
  const { t } = useTranslation()
  return (
    <Container className="mt-5">
      <h1 className="fw-light text-center">{t("hosting-page-title")}</h1>
      <Container className="m-3 text-center">{t("hosting-page-overview")}</Container>
    </Container>
  )
}

export default HostingOverviewSection
